<template>
  <div class="maincontacterdiv">
    <AppHeader />
    <Rejoindre />
    <AppFooter class="contacter-footer footer-section" :class="locale" />
  </div>
</template>

<script>
import Rejoindre from "@/components/form/rejoindre.vue";
import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/footer/Footer.vue";
export default {
  components: {
    Rejoindre,
    AppHeader,
    AppFooter,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
