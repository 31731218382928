<template>
  <ValidationProvider
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <label
      >{{ label }} <span class="text-secondary" v-if="required">*</span></label
    >
    <b-form-group class="radioContainer">
      <b-form-radio-group
        :state="errors[0] ? false : valid ? true : null"
        v-model="innerValue"
        :options="$attrs.options"
        class="radiogroup"
      >
      </b-form-radio-group>
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "../mixins/input-mixin";
export default {
  mixins: [inputMixin],
};
</script>
