<template>
  <div class="mb-3">
    <label v-if="locale == 'fr'"
      >{{ $t("rejoindre.jobslabel") }}
      <span class="text-secondary">*</span></label
    >
    <label v-else-if="locale == 'ar'"
      ><span class="text-secondary">*</span>{{ $t("rejoindre.jobslabel") }}
    </label>
    <div class="checkbox__container">
      <CheckBox
        @update-field="updateField"
        :cb="checkboxesData"
        :options="options"
      />
    </div>
  </div>
</template>

<script>
import CheckBox from "./checkbox.vue";
export default {
  props: ["checkboxesData", "options"],
  components: {
    CheckBox,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    updateField(field) {
      const { name, newVal } = field;
      this.$emit("update-field", { newVal, name: name });
    },
  },
};
</script>

<style lang="scss" scoped></style>
