<template>
  <div class="formPage rejoindre__formPage" :class="locale">
    <div
      class="formContainer position-relative rejoindreFormContainer"
      :class="locale"
    >
      <div class="formContainer__badge" :class="locale">
        <img
          class="img-fluid"
          width="160"
          :src="require('../../assets/images/form/sotherma_blue1.png')"
          alt=""
        />
      </div>
      <div class="formContainer__contacter" :class="locale">
        <p class="text-white text-uppercase">{{ $t("rejoindre.title") }}</p>
      </div>
      <div class="pt-5">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="container">
              <div class="row">
                <div
                  class="col-sm-6 col-12 formContainer__inputCol"
                  :class="locale"
                  v-for="{
                    name,
                    label,
                    component,
                    required,
                    ...attrs
                  } in formobjs"
                  :key="name"
                >
                  <component
                    :is="component"
                    :name="name"
                    :label="label"
                    v-bind="attrs"
                    :required="required"
                    @update-field="updateField"
                  >
                    <template v-if="attrs.children">
                      <option
                        v-for="({ value, text, ...rest }, i) in attrs.children"
                        v-bind="rest"
                        :key="i"
                        :value="value"
                      >
                        {{ text }}
                      </option>
                    </template>
                  </component>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-6 col-12 formContainer__inputCol"
                  :class="locale"
                >
                  <SelectInput
                    :name="age.name"
                    :label="age.label"
                    :rules="age.rules"
                    :id="age.name"
                    @update-field="updateField"
                    class="formContainer__select age"
                  >
                    <option
                      v-for="(option, i) in age.options"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </SelectInput>
                </div>
                <div
                  class="col-md-6 col-12 formContainer__inputCol"
                  :class="locale"
                >
                  <CheckBoxContainer
                    :checkboxesData="checkboxData"
                    :options="cbOptions"
                    @update-field="updateField"
                  />
                  <TextArea
                    :name="comments.name"
                    :rules="comments.rules"
                    :label="comments.label"
                    :initialValue="comments.initialValue"
                    :id="comments.name"
                    :required="comments.required"
                    @update-field="updateField"
                  />
                </div>

                <div
                  class="
                    col-md-6 col-12
                    formContainer__inputCol
                    rejoindre__formPage__twinInputs
                  "
                  :class="locale"
                >
                  <div class="mb-4">
                    <TextInput
                      :name="telephone.name"
                      :label="telephone.label"
                      :id="telephone.name"
                      @update-field="updateField"
                      :rules="telephone.rules"
                      :required="telephone.required"
                    />
                  </div>
                  <div class="mb-4">
                    <TextInput
                      :name="email.name"
                      :label="email.label"
                      :id="email.name"
                      @update-field="updateField"
                      :rules="email.rules"
                      :required="email.required"
                    />
                  </div>

                  <SelectInput
                    :name="profession.name"
                    :label="profession.label"
                    :id="profession.name"
                    :rules="profession.rules"
                    @update-field="updateField"
                    :required="profession.required"
                  >
                    <option
                      v-for="(option, i) in profession.options"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </SelectInput>

                  <!-- <p class="text-secondary mt-4 pb-2 ml-4">* Champs requis.</p> -->
                  <h4
                    class="
                      rejoindre__formPage__successMessage
                      text-md-center text-left
                      mt-xl-5 mt-0
                      pt-md-5
                      pb-md-0 pb-3
                      pt-5
                    "
                    style="color: green; letter-spacing: 2px"
                    v-if="formSent"
                  >
                    <b>{{ $t("rejoindre.success") }}</b>
                  </h4>
                </div>

                <div class="col-md-6 col-12 px-0 px-sm-2">
                  <div class="mt-md-4 mt-3 px-2">
                    <FileInput @update-field="updateField" />
                  </div>
                  <div class="text-center mt-md-5 mt-4">
                    <Button
                      :text="$t('rejoindre.contact_send')"
                      :disabled="formSending"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import veeValidateExtends from "../mixins/rules";
import TextInput from "./input.vue";
import SelectInput from "./select.vue";
import TextArea from "./TextArea.vue";
import Button from "./Button.vue";
import RadioInput from "./radio.vue";
import CheckBoxContainer from "./CheckboxContainer.vue";
import FileInput from "./File.vue";
[...veeValidateExtends()];

export default {
  name: "BootstrapForm",
  props: {
    stacked: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    FileInput,
    Button,
    ValidationObserver,
    TextInput,
    SelectInput,
    TextArea,
    RadioInput,
    CheckBoxContainer,
  },
  data: () => {
    return {
      formData: {},
      loading: false,
      formSent: false,
      formSending: false,
      errorMessage: "",
      formobjs: [],
      telephone: {},

      age: {},

      comments: {},
      email: {},
      profession: {},
      checkboxData: {},

      cbOptions: [],
    };
  },
  mounted() {
    this.formobjs = this.$i18n.t("rejoindre.formobjs");
    this.telephone = this.$i18n.t("rejoindre.telephone");
    this.age = this.$i18n.t("rejoindre.age");
    this.comments = this.$i18n.t("rejoindre.comments");
    this.email = this.$i18n.t("rejoindre.email");
    this.profession = this.$i18n.t("rejoindre.profession");
    this.checkboxData = this.$i18n.t("rejoindre.checkboxData");
    this.cbOptions = this.$i18n.t("rejoindre.cbOptions");
  },
  watch: {
    "$i18n.locale"(value) {
      this.formobjs = this.$i18n.t("rejoindre.formobjs");
      this.telephone = this.$i18n.t("rejoindre.telephone");
      this.age = this.$i18n.t("rejoindre.age");
      this.comments = this.$i18n.t("rejoindre.comments");
      this.email = this.$i18n.t("rejoindre.email");
      this.profession = this.$i18n.t("rejoindre.profession");
      this.checkboxData = this.$i18n.t("rejoindre.checkboxData");
      this.cbOptions = this.$i18n.t("rejoindre.cbOptions");
    },
  },
  computed: {
    formFields() {
      return this.$store.state.formSchema;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    updateField(field) {
      const { name, newVal } = field;
      if (name != "mobility" && name != "permit" && !newVal && !newVal.length)
        return delete this.formData[name];
      this.formData[name] = newVal;
    },

    async onSubmit() {
      const server = "/api";
      const endpoint = "/contact/join-us";
      try {
        let form_data = new FormData();
        for (var key in this.formData) {
          if (key != "CV") form_data.append(`form.${key}`, this.formData[key]);
          else form_data.append(`file`, this.formData[key]);
        }
        // for (var pair of form_data.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        const rawResponse = await fetch(`${server}${endpoint}`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Accept-Encoding": "gzip, deflate, br",
          },
          body: form_data,
        });
        this.formSent = true;
        this.formSending = false;
        setTimeout(function() {
          location.reload();
        }, 750);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
