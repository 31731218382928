<template>
  <ValidationProvider
    :name="cb.name"
    :rules="cb.rules"
    v-slot="{ valid, errors }"
  >
    <div class="checkbox__container">
      <div class="row align-items-center">
        <b-form-checkbox
          v-for="(ckb, i) in options"
          :id="ckb.value"
          :key="i"
          :state="errors[0] ? false : valid ? true : null"
          class="col-sm-6 col-12 mb-1"
          :value="ckb.value"
          v-model="selected"
          ><span class="checkbox__label d-block">{{
            ckb.text
          }}</span></b-form-checkbox
        >
      </div>
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>
// import inputMixin from "../mixins/input-mixin";
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {
      innerValue: "",
      selected: [],
    };
  },
  props: ["cb", "options", "inputclass"],
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("update-field", { newVal, name: this.$attrs.name });
    },
    selected(newVal) {
      this.$emit("update-field", { newVal, name: 'jobs' });
    },
    // Handles external model changes.
    initialValue(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.initialValue) {
      if (this.$attrs.type !== "file") {
        this.innerValue = this.initialValue;
      } else {
        this.innerValue = [];
      }
    }
  },
};
</script>
