<template>
  <ValidationProvider rules="required" v-slot="{ errors, validate }">
    <label v-if="locale == 'fr'" class="text-left"
      >{{ $t("rejoindre.fileinput") }}
      <span class="text-secondary">*</span></label
    >
    <label v-else-if="locale == 'ar'" class="text-left"
      ><span class="text-secondary">*</span>{{ $t("rejoindre.fileinput") }}
    </label>
    <b-form-file
      v-model="file"
      :state="errors[0] ? false : validate ? true : null"
      @change="validate"
      :placeholder="$t('rejoindre.choosefile')"
      :browse-text="$t('rejoindre.fileselect')"
    ></b-form-file>
    <span>{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    file(newVal) {
      this.$emit("update-field", { newVal, name: "CV" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
